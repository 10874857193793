<template>
  <div>
    <!-- <img src="@/assets/img/top.png" class="topImg" alt="" srcset=""> -->
    <!-- <div class="topBox bet">
      <div class="weatherBox divFlex alignCenter flexCenter">
        <img src="@/assets/weather/小雨.png" alt="">
        <span>小雨 24℃</span>
      </div>
      <div class="titleName">
        <div class="border2">乡村地图</div>
        <div class="border1">乡村地图</div>
      </div>
      <div class="timeBox">10:28:59</div>
    </div> -->

    <!-- <img src="@/assets/img/bottom.png" class="bottomImg" alt="" srcset=""> -->
    <!-- <div class="idxBoxiframe" v-if="iframeUrl">
      <iframe style="width:100%;height: 100%;" :src="iframeUrl" frameborder="0"></iframe>
    </div> -->
    <div class="warningBox divFlex alignCenter" v-if="noticeInfo">
      <img style="width:0.4rem;" src="@/assets/img/573.png" alt="">
      <div class="warnSwiper grow hid">
        <SwiperImage1 :auto="true" type="预警" :images="noticeInfo" />

      </div>

    </div>
    <!-- <div ref="detailBox" class="detailBox">

      <div class="detailContentBox">
        <img class="img1" src="@/assets/img/边框.png" alt="">
        <img class="img2" src="@/assets/img/边框.png" alt="">
        <img class="img3" src="@/assets/img/边框.png" alt="">
        <img class="img4" src="@/assets/img/边框.png" alt="">
        <div class="detai_lContent column alignCenter">

          <div class="detailTitle">崇仁乡</div>
          <div class="detailDesc">
            崇仁乡隶属于福建省南平市光泽县地处光泽县中北部东、南与鸾凤乡相连西与华桥乡接壤北与寨里镇毗邻行...区域...积131.18平方千米。截至2019年末崇仁...户籍人口为13228人。
            宋代设东寨府。1984年9月撤销崇仁公社改设崇仁乡。
          </div>
          <div class="allImgBox">
            <img src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg" alt="">
            <SwiperImage1 type="列表"
              :images="['https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg']" />
          </div>
          <div class="detailBtn">
            查看详情
          </div>

        </div>

      </div>
    </div> -->
    <div id="map">

    </div>
  </div>
</template>

<script>
import {
  getDataList,
  getDataDetail,
  allVr,
  allList,
  getLastNotice
} from "@/api";
import { SwiperImage1 } from "@/components";
export default {
  components: {
    SwiperImage1
  },
  data() {
    return {
      countyList: [],
      iframeUrl: '',
      noticeInfo: false
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    let _this = this
    this.$nextTick(() => {
      this.initMap();
    })

    getLastNotice().then(res => {
      this.noticeInfo = res.data
    })

    $(document).on("click", ".homeDetail", function () {
      let id = $(this).attr("data-id");
      _this.$router.push({
        path: '/CountyDetail',
        query: {
          id
        }
      })
    });
    $(document).on("click", ".newsDetail", function () {
      let id = $(this).attr("data-id");
      _this.$router.push({
        path: '/ActionDetail',
        query: {
          id
        }
      })
    });

  },
  methods: {
    initMap() {
      var map = L.map('map', {
        center: [27.6029, 117.3205773],
        zoom: 13,
        zoomControl: false, //禁用 + - 按钮
        doubleClickZoom: true, // 禁用双击放大
        attributionControl: false, // 移除右下角leaflet标识
        // layers:[{
        //     name: "谷歌影像",
        //     // icon: "img/basemaps/google_img.png",
        //     type: "xyz",
        //     url: "https://cdn.npswmb.gov.cn/map/xpmap/{z}/{x}/{y}.jpg",
        //     show: true
        //   }]
        // trackResize:false
      });
      window.map = map;

      // 天地图图层
      L.tileLayer(
        "https://t{s}.tianditu.gov.cn/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk=56468124079edba9ff62708c089e5eb7",
        {
          subdomains: "1234",
          maxZoom: 22,
          maxNativeZoom: 18,
          minZoom: 0,
        }
      ).addTo(map);

                   
      L.tileLayer('https://cdn.npswmb.gov.cn/map/np_map/{z}/{x}/{y}.jpg', {
        subdomains: "1234",
        maxZoom: 22,
        maxNativeZoom: 18,
        minZoom: 0,
      }).addTo(map);

      // ------------------------------------
      this.getCountyList();
      this.getAllVr()


      // var myIcon11 = L.divIcon({ html: '888888888888888888888888888888' });
      // // 你可以在 .my-div-icon CSS 中设置样式

      // L.marker([27.6040, 117.337773], { icon: myIcon11 }).addTo(map)


    },
    getCountyList() {
      getDataList().then(res => {
        // console.log(res);

        this.countyList = res.data;
        res.data.forEach(e => {
          var myIcon = L.icon({
            iconUrl: e.level == 1 ? require('@/assets/img/lanse.png') : require('@/assets/img/522.png'),
            iconSize: [68 * dif / 100, 126 * dif / 100],
            iconAnchor: [34 * dif / 100, 126 * dif / 100],
          });


          if (!e.position) return;
          let position = JSON.parse(e.position)
          console.log(position)
          let marker = L.marker([position.lat, position.lng], { icon: myIcon }).addTo(map);
          let img1 = require('@/assets/img/边框.png')
          let dom = `
          <div class="column alignCenter">
            <div ref="detailBox" class="detailBox">
            <div class="detailContentBox">
              <img class="img1" src="${img1}" alt="">
              <img class="img2" src="${img1}" alt="">
              <img class="img3" src="${img1}" alt="">
              <img class="img4" src="${img1}" alt="">
              <div class="detai_lContent column alignCenter">

                <div class="detailTitle">${e.name}</div>
                <div class="detailDesc">
                  ${e.introduce || '--'}
                </div>`

          if (e.images) {
            dom += `<div class="allImgBox">
                  <img src="${(e.images.split(','))[0]}" alt="">
                  </div>`
          }
          dom += `<div data-id="${e.id}" class="detailBtn homeDetail">
                  查看详情
                </div>

              </div>

            </div>
            
          </div>
          <div class="detailContentBoxBefore"></div>
        </div>
          `
          marker.bindPopup(dom, { offset: L.point(0, -1.26 * dif - 14), closeOnClick: false });
        })
      })
    },
    getAllVr() {
      allVr().then(res => {

        var myIcon = L.icon({
          iconUrl: require('@/assets/img/vr.png'),
          iconSize: [76 * dif / 100, 67 * dif / 100],
          iconAnchor: [38 * dif / 100, 33.5 * dif / 100],
        });
        res.data.forEach(e => {
          if (!e.position) return;
          let position = JSON.parse(e.position)
          console.log(position)
          let marker1 = L.marker([position.lat, position.lng], { icon: myIcon }).addTo(map);
          marker1.on('click', (event) => {
            this.$router.push({
              path: '/Views',
              query: {
                url: e.url
              }
            })
          });
        })
      })

      allList().then(res => {

        var myIcon = L.icon({
          iconUrl: require('@/assets/img/66.png'),
          iconSize: [57 * dif / 100, 77 * dif / 100],
          iconAnchor: [28.5 * dif / 100, 38.5 * dif / 100],
        });
        console.log(res)
        res.data.forEach(e => {
          if (!e.position) return;
          let position = JSON.parse(e.position)

          console.log(position)
          let marker1 = L.marker([position.lat, position.lng], { icon: myIcon }).addTo(map);
          let img1 = require('@/assets/img/边框.png')
          let dom = `
            <div ref="detailBox" class="detailBox">
            <div class="detailContentBox">
              <img class="img1" src="${img1}" alt="">
              <img class="img2" src="${img1}" alt="">
              <img class="img3" src="${img1}" alt="">
              <img class="img4" src="${img1}" alt="">
              <div class="detai_lContent column alignCenter">

                <div class="detailTitle">${e.title}</div>
                <div class="detailDesc">
                  车市测试测试车市测试测试车市测试测试车市测试测试
                </div>`

          if (e.photoList) {
            dom += `<div class="allImgBox">
                  <img src="${e.photoList[0]}" alt="">
                  </div>`
          }
          dom += `<div data-id="${e.id}" class="detailBtn newsDetail">
                  查看详情
                </div>

              </div>
            </div>
          </div>`
          marker1.bindPopup(dom, { offset: L.point(0, -130 * dif / 100), closeOnClick: false });
        })
      })
    }
  },
};
</script>
<style lang="scss" scoped></style>

